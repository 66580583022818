var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _vm._m(0),
    _c("div", { staticClass: "md-layout" }, [
      _c(
        "div",
        { staticClass: "md-layout-item" },
        [
          _c(
            "md-card",
            { staticClass: "md-card-plain" },
            [
              _c("md-card-content", [
                _c("div", { staticClass: "iframe-container hidden-sm" }, [
                  _c(
                    "iframe",
                    {
                      attrs: { src: "https://vuematerial.io/components/icon" },
                    },
                    [
                      _c("p", [
                        _vm._v("Your browser does not support iframes."),
                      ]),
                    ]
                  ),
                ]),
                _c("div", { staticClass: "hidden-md" }, [
                  _c("h5", [
                    _vm._v(
                      "The icons are visible on Desktop mode inside an iframe. Since the iframe is not working on Mobile and Tablets please visit the icons on their original page on Google. Check the "
                    ),
                    _c(
                      "a",
                      {
                        attrs: {
                          href: "https://design.google.com/icons/",
                          target: "_blank",
                        },
                      },
                      [_vm._v("Material Icons")]
                    ),
                  ]),
                ]),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "header text-center" }, [
      _c("h3", { staticClass: "title" }, [_vm._v("Material Design Icons")]),
      _c("p", { staticClass: "category" }, [
        _vm._v("Handcrafted by our friends from "),
        _c(
          "a",
          {
            attrs: {
              target: "_blank",
              href: "https://design.google.com/icons/",
            },
          },
          [_vm._v("Google")]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }